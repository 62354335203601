import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";



function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="blue">Governance App Rules</strong>
            </h1>
            <p>
              <p>
                1. For this beta test, all Choice used for either vote will be returned within 72 hours of the governance conclusion, March 17, 2025.
              </p>
              <p>
                2. The winners will receive an additional 5,000 Choice for each winning vote.
              </p>
              <p>
                3. All votes are 50,000 Choice.
              </p>
              <p>
                4. Use this app at your own risk, all choices are final.
              </p>
              <p>
                5. This app is operational only for governance, research, and testing.
              </p>
              <p>
                6. To vote: (1) connect your Metamask to your Base account; (2) click on your choice of yes or no; and (3) confirm the transaction via Metamask.
              </p>
              <p>
                7. Thank you for participating in this first governance of Choice on Base.
              </p>
              
            </p>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="blue">Gaming App Rules</strong>
            </h1>
            <p>
              <p>
                1. For this beta test, all Choice used for either bet will be returned within 72 hours of the game conclusion, February 9, 2025.
              </p>
              <p>
                2. The winners will receive an additional 20,000 Choice for each winning bet.
              </p>
              <p>
                3. Thank losers will recieve an additional 10,000 Choice for each losing bet.
              </p>
              <p>
                4. All bets are 20,000 Choice.
              </p>
              <p>
                5. Use this app at your own risk, all choices are final.
              </p>
              <p>
                6. This app is operational only for market research and testing.
              </p>
              <p>
                7. To vote: (1) connect your Metamask to your Base account; (2) click on your choice of yes or no; and (3) confirm the transaction via Metamask.
              </p>

              
            </p>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="blue">About Choice</strong>
            </h1>
            <p>
            Choice Coin is a decentralized autonomous network (DAN). The focus for the DAN is building open source software for decentralized governance. Choice Coin started at the 2021 MIT Bitcoin Hackathon, where the projects co-creators won the top prize with their project Algorand Autonomous, a primitve DAO with novel governance technology. Since that time, the project has partnered with several institutional and decentralized organizations to build one of the largest open source governance projects in the world, with over 100 global contributors.            
            </p>

            
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default About;
